import produce from "immer";
import { User } from "oidc-client-ts";
import {
    ICompanyModel,
    ICompanySummaryModel,
    IGetParametersModel,
    IParametersModel,
    IProfileModel,
    ITenantSummaryModel
} from "proxy/apiProxy"
import { AnyActionOf, produceActionFactories } from "tools/lib/store";
export interface IStoreGridState {
    sorting: {
        columnName: string;
        direction: 'asc' | 'desc';
    }[];
    expandedRows: (number | string)[] | undefined;
    expandedDetails: (number | string)[] | undefined;
    filters: {
        columnName: string;
        operation?: string;
        value?: string;
    }[];
    grouping: {
        columnName: string;
    }[];
    columnWidths: {
        columnName: string;
        width: number | string;
    }[];
    columnOrder: string[];
    hiddenColumn: string[];
    expandedGroups?: string[];
}

export interface ISetGridStatePayload {
    screenKey: string;
    sectionKey: string;
    state: IStoreGridState;
}

export interface IApplicationLoadedPayload {
    profile?: IProfileModel;
    currentTenant?: ICompanyModel;
    currentTenantImage?: string;
    parameters?: IGetParametersModel;
    accessibleTenants: ICompanySummaryModel[] | undefined;
    initialUrl: string | undefined
}

export interface ITenantImageLoadedPayload {
    tenantId: number;
    imageUrl?: string;
}

export const ActionFactories = produceActionFactories({
    setGridState: (payload: ISetGridStatePayload) => payload,
    requestLogout: () => undefined,
    onSigninCallback: (payload: User) => payload,
    searchShortcut: () => undefined,

    dummy: () => undefined,
    firstTenantCreate: (payload: ITenantSummaryModel) => payload,
    firstTenantCreated: (payload: ICompanySummaryModel) => payload,

    switchTenant: (payload: number) => payload,
    applicationLoaded: (payload: IApplicationLoadedPayload) => payload,
    applicationLoadedWithNoGrants: () => undefined,
    applicationLoadedWithNoTenant: () => undefined,
    tenantImageLoaded: (payload: ITenantImageLoadedPayload) => payload,
    tenantsImageLoad: () => undefined,
    tenantAdded: (payload: ICompanySummaryModel) => payload,
    initialUrlClear: () => undefined,

    // Menu
    openMenuDrawer: () => undefined,
    closeMenuDrawer: () => undefined,
    // /**@deprecated */
    // menuExpandLevel1: (level1Idx: number) => level1Idx,
    // /**@deprecated */
    // menuSelect: (selection: IMenuSelection) => selection,
    // /**@deprecated */
    // replaceCacheScreenMenuLocation: (payload: Map<ScreenKey, IMenuLocation3>) => undefined,
    menuDefinitionLoad: () => undefined,
    menuDefinitionLoaded: (payload: IApplicationMenuState) => undefined,
    // /**@deprecated */
    // newMenuShouldBeLoaded: () => undefined,
});
export interface IScreenMenuState {
    urlTemplate: string;
    params?: Record<string, string | number>;
    label: string;
    children: IScreenMenuState[];
    parent: IScreenMenuState | undefined;
    keywords?: string;
}
export interface IModuleMenuState {
    label: string;
    icon: string;
    screens: IScreenMenuState[];
}
export interface ICategoryMenuState {
    label: string;
    subMenus: IModuleMenuState[];
}
export interface IApplicationMenuState {
    categories: ICategoryMenuState[];
    home?: IScreenMenuState;
}
export interface IState {
    gridStates: {
        [screenKey: string]: {
            [sectionKey: string]: IStoreGridState;
        }
    },
    currentUser?: IProfileModel;
    parameters?: IParametersModel;
    currentTenant?: ICompanyModel;
    currentTenantImageUrl?: string;
    searchShortcutGuid: number;
    applicationLoading: boolean;
    accessibleTenants: ICompanySummaryModel[];
    noTenant: boolean;
    tenantsImageUrls: Record<number, string>;
    tenantCreating: boolean;
    initialUrl: string | undefined;

    // Menu
    applicationMenu: IApplicationMenuState;
    menuDrawerOpened: boolean;
    // /**@deprecated */
    // selectedMenuNode: IMenuSelection;
    // /**@deprecated */
    // menuDefinition: IMenuDefinition;
    menuLoading: boolean;
    // /**@deprecated */
    // menuLoaded: boolean;
    // /**@deprecated */
    // cacheScreenToMenu: Map<ScreenKey, IMenuLocation3> | undefined;
}

export type Action = AnyActionOf<typeof ActionFactories>;

export function reducer(
    state: IState = {
        gridStates: {},
        searchShortcutGuid: 0,
        applicationLoading: false,
        tenantsImageUrls: {},
        tenantCreating: false,
        accessibleTenants: [],
        noTenant: false,
        initialUrl: undefined,

        //Menu
        applicationMenu: { categories: [] },
        menuDrawerOpened: true,
        // menuDefinition: makeReadableCopy(defaultMenu),
        // selectedMenuNode: {},
        // cacheScreenToMenu: undefined,
        menuLoading: false,
        // menuLoaded: false
    },
    action: Action
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "tenantAdded":
                draft.accessibleTenants.push(action.payload);
                break;
            case "setGridState":
                if (!draft.gridStates[action.payload.screenKey]) {
                    draft.gridStates[action.payload.screenKey] = {};
                }
                draft.gridStates[action.payload.screenKey][action.payload.sectionKey] = action.payload.state;
                break;
            case "applicationLoaded":
                draft.currentTenant = action.payload.currentTenant;
                draft.currentTenantImageUrl = action.payload.currentTenantImage;
                draft.currentUser = action.payload.profile;
                draft.parameters = action.payload.parameters?.parameters;
                draft.accessibleTenants = action.payload.accessibleTenants ?? [];
                draft.noTenant = false;
                draft.applicationLoading = false;
                draft.initialUrl = action.payload.initialUrl
                break;
            case "initialUrlClear":
                draft.initialUrl = undefined
                break
            case "applicationLoadedWithNoTenant":
                delete draft.currentTenant;
                delete draft.currentTenantImageUrl;
                delete draft.currentUser;
                delete draft.parameters;
                draft.accessibleTenants = [];
                draft.noTenant = true;
                draft.applicationLoading = false;
                break;
            case "applicationLoadedWithNoGrants":
                delete draft.currentTenant;
                delete draft.currentTenantImageUrl;
                delete draft.currentUser;
                delete draft.parameters;
                draft.accessibleTenants = [];
                draft.noTenant = false;
                draft.applicationLoading = false;
                break;
            case "searchShortcut":
                draft.searchShortcutGuid = draft.searchShortcutGuid + 1;
                break;
            case "onSigninCallback":
                draft.applicationLoading = true;
                break;
            case "tenantImageLoaded":
                if (action.payload.imageUrl) draft.tenantsImageUrls[action.payload.tenantId] = action.payload.imageUrl;
                else delete draft.tenantsImageUrls[action.payload.tenantId];
                break;
            case "firstTenantCreate":
                draft.tenantCreating = true;
                break
            // Menu
            case "closeMenuDrawer":
                draft.menuDrawerOpened = false;
                break;
            case "openMenuDrawer":
                draft.menuDrawerOpened = true;
                break;
            // case "menuExpandLevel1":
            //     draft.selectedMenuNode = { level1Index: action.payload }
            //     break
            // case "menuSelect":
            //     draft.selectedMenuNode = action.payload
            //     break
            // case "replaceCacheScreenMenuLocation":
            //     draft.cacheScreenToMenu = action.payload
            //     break
            case "menuDefinitionLoaded":
                draft.applicationMenu = action.payload;
                draft.menuLoading = false;
                break;
            case "menuDefinitionLoad":
                draft.menuLoading = true;
                break
            // case "menuDefinitionLoaded":
            //     draft.menuLoading = false
            //     const serverMenu = action.payload
            //     let userMenu: IMenuDefinition | undefined = undefined
            //     if (serverMenu) {
            //         // First version of menu was just an array
            //         if (Array.isArray(serverMenu)) {
            //             userMenu = {
            //                 home: undefined,
            //                 menu: serverMenu,
            //                 version: 1
            //             }
            //         } else {
            //             userMenu = serverMenu as IMenuDefinition // We blindly trust the server
            //         }
            //     } else {
            //         userMenu = makeReadableCopy(defaultMenu);
            //     }
            //     if (!userMenu.menu) {
            //         userMenu.menu = [];
            //     }
            //     if (isGranted(state.currentUser, IFeatureModel.SysAdmin)) {
            //         userMenu.menu = [...userMenu.menu, ...makeReadableCopy(sysAdminMenu).menu]
            //     }
            //     draft.menuDefinition = userMenu
            //     draft.cacheScreenToMenu = undefined
            //     draft.menuLoaded = true
            //     break
            // case "newMenuShouldBeLoaded":
            //     draft.menuLoaded = false
            //     break
        }
    });
}
// function makeReadableCopy<T>(obj: T) {
//     return JSON.parse(JSON.stringify(obj)) as T;
// }