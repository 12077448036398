import SearchPanel from "tools/components/SearchPanel";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";
import { IFeatureModel, IInvoiceSummaryModel } from "proxy/apiProxy";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEntityName } from "tools/lib/modelUtils";
import { useScreenNavigate } from "tools/routing/screenRouteHooks";
import { useCallback, useEffect } from "react";
import React from "react";

export const invoicesScreen = {
    route: "/invoices" as const,
    component: Invoices,
    label: "Invoices",
}

function Invoices() {
    const { invoices, invoiceAllLoading, dictionaries: { entities } } = useReduxSelections("invoice");
    const { invoiceLoadAll } = useReduxActions("invoice");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const navigate = useScreenNavigate();
    useEffect(() => {
        invoiceLoadAll()
    }, [invoiceLoadAll]);

    const handleRowClick = useCallback(({ id }: IInvoiceSummaryModel) => navigate("Invoice", { id }), [navigate])
    const handleAddNewClick = useCallback(() => navigate("Invoice", { id: 0 }), [navigate])

    const columns: IColumnDefinition[] = [
        {
            name: "Number",
            title: "Number",
            getCellValue: (row: IInvoiceSummaryModel) => row.number,
            filteringEnabled: true
        },
        {
            name: "Currency",
            title: "Currency",
            getCellValue: (row: IInvoiceSummaryModel) =>
                row.currencyId !== undefined ? referenceCurrencies[row.currencyId]?.isoCode : undefined,
            filteringEnabled: true,
        },
        {
            name: "Customer",
            title: "Customer",
            getCellValue: (row: IInvoiceSummaryModel) =>
                row.customerId !== undefined ? getEntityName(entities[row.customerId]) : undefined,
            filteringEnabled: true,
        },
        {
            name: "Vendor",
            title: "Vendor",
            getCellValue: (row: IInvoiceSummaryModel) =>
                row.vendorId !== undefined ? getEntityName(entities[row.vendorId]) : undefined,
            filteringEnabled: true,
        },
        {
            name: "InvoiceDate",
            title: "Invoice Date",
            getCellValue: (row: IInvoiceSummaryModel) => row.invoiceDate,
            filteringEnabled: true,
            columnType: "date"
        },
        {
            name: "DueDate",
            title: "Due Date",
            getCellValue: (row: IInvoiceSummaryModel) => row.dueDate,
            filteringEnabled: true,
            columnType: "date"
        }
    ];

    const state: IGridState = {
        "Number": { width: 200 },
        "Customer": { width: 200 },
        "Vendor": { width: 200 },
        "Currency": { width: 200 },
        "InterestRate": { width: 200 },
        "InvoiceDate": { width: 200 },
        "DueDate": { width: 200 },
    };

    const getRowKey = React.useCallback((row: IInvoiceSummaryModel) => row.id, []);

    return <SearchPanel title="Invoices" isQuerying={invoiceAllLoading}
        addAllowed={IFeatureModel.ManagedPortfolioWrite}
        onAddClick={handleAddNewClick} >
        <ExtendedGrid
            rows={invoices}
            columns={columns}
            getRowId={getRowKey}
            onRowClick={handleRowClick}
            initialState={state}
            userCanGroup={true}
            defaultExportFileName="invoices.xlsx" />
    </SearchPanel>
}
