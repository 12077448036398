import { companyScreen, entityGroupScreen, personScreen } from "features/Entity/Entity";
import { companiesScreen, entityGroupsScreen, peopleScreen } from "features/Entity/Entities";
import { sicavsScreen } from "features/Sicav/Sicavs";
import { sicavScreen } from "features/Sicav/Sicav";
import {
    processExecutionNewScreen,
    processExecutionRenewScreen,
    processExecutionScreen
} from "features/ProcessExecution/ProcessExecution";
import { processExecutionsScreen } from "features/ProcessExecution/ProcessExecutions";
import { accountBalancesScreen } from "features/AccountBalance/AccountBalances";
import { bankAccountsScreen } from "features/BankAccount/BankAccounts";
import { bankAccountScreen } from "features/BankAccount/BankAccount";
import { batchesScreen } from "features/Batch/Batches";
import { batchScreen } from "features/Batch/Batch";
import { cashMovementsScreen } from "features/CashMovement/CashMovements";
import { cashMovementScreen } from "features/CashMovement/CashMovement";
import { connectorsScreen } from "features/Connectors/Connectors";
import { genericDashboardScreen } from "features/GenericDashboard/GenericDashboard"
import { genericScreenScreen } from "features/GenericScreen/GenericScreen"
import {
    counterpartyRelationshipsScreen,
    investorRelationshipsScreen,
    roleRelationshipsScreen
} from "features/Relationship/Relationships";
import {
    counterpartyRelationshipScreen,
    investorRelationshipScreen,
    roleRelationshipScreen
} from "features/Relationship/Relationship";
import { customScreensScreen } from "features/CustomScreen/CustomScreens";
import { customScreenScreen } from "features/CustomScreen/CustomScreen";
import { dashboardsScreen } from "features/Dashboard/Dashboards";
import { dashboardScreen } from "features/Dashboard/Dashboard";
import { dashboardGetFilesScreen } from "features/Dashboard/ActionGetFiles";
import { dashboardExecutionScreen } from "features/Dashboard/ActionExecutions";
import { dashboardDropFilesScreen } from "features/Dashboard/ActionDropFiles";
import { developmentItemScreen } from "features/DevelopmentItem/DevelopmentItem";
import { developmentItemsScreen } from "features/DevelopmentItem/DevelopmentItems";
import { documentDefinitionsScreen } from "features/DocumentDefinition/DocumentDefinitions";
import { documentDefinitionScreen } from "features/DocumentDefinition/DocumentDefinition";
import {
    developmentItemClassificationTypesScreen,
    documentClassificationTypesScreen,
    entityClassificationTypesScreen,
    investorClassificationTypesScreen,
    movementClassificationTypesScreen,
    noteClassificationTypesScreen,
    portfolioTransactionClassificationTypesScreen,
    processClassificationTypesScreen,
    roleRelationshipClassificationTypesScreen,
    securityClassificationTypesScreen
} from "features/Classification/ClassificationTypes";
import { batchInstancesScreen } from "features/BatchInstance/BatchInstances";
import { batchInstanceScreen } from "features/BatchInstance/BatchInstance";
import { processDefinitionsScreen } from "features/ProcessDefinition/ProcessDefinitions";
import { processDefinitionScreen } from "features/ProcessDefinition/ProcessDefinition";
import { fxRatesScreen } from "features/FxRates/FxRates";
import { fxRateScreen } from "features/FxRates/FxRate";
import { parametersScreen } from "features/Parameters/Parameters";
import { globalPortfolioComplianceScreen } from "features/GlobalPortfolioCompliance/GlobalPortfolioCompliance";
import { globalPortfolioDealingScreen } from "features/GlobalPortfolioDealing/GlobalPortfolioDealing";
import { portfolioHistoricalValuesScreen } from "features/PortfolioHistoricalValue/PortfolioHistoricalValues";
import { changesHistoryScreen } from "features/Versioning/ChangesHistory";
import { indexesScreen } from "features/Indexes/Indexes";
import { indexScreen } from "features/Indexes/Index";
import {
    developmentItemClassificationTypeScreen,
    entityClassificationTypeScreen,
    investorClassificationTypeScreen,
    movementClassificationTypeScreen,
    noteClassificationTypeScreen,
    portfolioTransactionClassificationTypeScreen,
    processClassificationTypeScreen,
    roleRelationshipClassificationTypeScreen,
    securityClassificationTypeScreen,
    documentClassificationTypeScreen,
} from "features/Classification/ClassificationType";
import { loanScreen } from "features/Loan/Loan";
import { loansScreen } from "features/Loan/Loans";
import { monitoringMacroCallScreen } from "features/MonitoringMacroCall/MonitoringMacroCall";
import { monitoringMacroCallsScreen } from "features/MonitoringMacroCall/MonitoringMacroCalls";
import { statisticDefinitionSetScreen } from "features/StatisticDefinitionSet/StatisticDefinitionSet";
import { statisticDefinitionSetsScreen } from "features/StatisticDefinitionSet/StatisticDefinitionSets";
import { macroScriptCategoriesScreen } from "features/MacroScriptCategory/MacroScriptCategories";
import { macroScriptCategoryScreen } from "features/MacroScriptCategory/MacroScriptCategory";
import { macroScriptsScreen } from "features/MacroScript/MacroScripts";
import { macroScriptScreen } from "features/MacroScript/MacroScript";
import { myPortfoliosScreen } from "features/ManagedPortfolio/Portfolios";
import { myPortfolioScreen } from "features/ManagedPortfolio/Portfolio";
import { myShareClassesScreen } from "features/ManagedShareClass/ShareClasses";
import { myShareClassScreen } from "features/ManagedShareClass/ShareClass";
import { mySicavsScreen } from "features/ManagedSicav/Sicavs";
import { mySicavScreen } from "features/ManagedSicav/Sicav";
import { noteScreen } from "features/Note/Note";
import { notesScreen } from "features/Note/Notes";
import { portfoliosComplianceScreen } from "features/PortfoliosCompliance/PortfoliosCompliance";
import { portfolioTradesScreen } from "features/PortfolioTrades/PortfolioTrades";
import { portfolioTransactionsScreen } from "features/PortfolioTransaction/PortfolioTransactions";
import { portfolioTransactionScreen } from "features/PortfolioTransaction/PortfolioTransaction";
import { positionsScreen } from "features/Position/Positions";
import { registerTransactionsScreen } from "features/RegisterTransaction/RegisterTransactions";
import { registerTransactionScreen } from "features/RegisterTransaction/RegisterTransaction";
import { reportsScreen } from "features/Report/Reports";
import { reportScreen } from "features/Report/Report";
import { securitiesScreen } from "features/Security/Securities";
import { securityScreen } from "features/Security/Security";
import { stressTestingScreen } from "features/StressTest/StressTestingSummary";
import { subFundScreen } from "features/Subfund/SubFund";
import { subFundsScreen } from "features/Subfund/SubFunds";
import { tenantManagersScreen } from "features/TenantManager/TenantManager";
import { tenantsAdministrationScreen } from "features/TenantsAdministration/TenantsAdministration";
import { valueAtRiskScreen } from "features/ValueAtRisk/ValueAtRisk";
import { tradeDatesScreen } from "features/TradeDate/TradeDates";
import { tradeDateScreen } from "features/TradeDate/TradeDate";
import { tracesScreen } from "features/Traces/Traces";
import { tenanciesScreen } from "features/Tenancy/Tenancies";
import { tenancyScreen } from "features/Tenancy/Tenancy";
import { reportTemplatesScreen } from "features/ReportTemplate/ReportTemplates";
import { reportTemplateScreen } from "features/ReportTemplate/ReportTemplate";
import { externalTemplateScreen } from "features/ExternalTemplate/ExternalTemplate";
import { reportTemplateCategoriesScreen } from "features/ReportTemplateCategory/ReportTemplateCategories";
import { reportTemplateCategoryScreen } from "features/ReportTemplateCategory/ReportTemplateCategory";
import { documentsScreen } from "features/Document/DocumentsScreen/Documents";
import { documentScreen } from "features/Document/DocumentScreen/Document";
import { invoiceScreen } from "./Invoice/Invoice";
import { invoicesScreen } from "./Invoice/Invoices";

export const screenDictionary = {
    AccountBalances: accountBalancesScreen,
    BankAccounts: bankAccountsScreen,
    BankAccount: bankAccountScreen,
    BatchDefinitions: batchesScreen,
    BatchDefinition: batchScreen,
    CashMovements: cashMovementsScreen,
    CashMovement: cashMovementScreen,
    Companies: companiesScreen,
    Company: companyScreen,
    EntityGroups: entityGroupsScreen,
    EntityGroup: entityGroupScreen,
    Connectors: connectorsScreen,
    Counterparties: counterpartyRelationshipsScreen,
    Counterparty: counterpartyRelationshipScreen,
    CustomScreens: customScreensScreen,
    CustomScreen: customScreenScreen,
    Dashboards: dashboardsScreen,
    Dashboard: dashboardScreen,
    DashboardsGetFiles: dashboardGetFilesScreen,
    DashboardsExecute: dashboardExecutionScreen,
    DashboardsDropFiles: dashboardDropFilesScreen,
    DevelopmentItems: developmentItemsScreen,
    DevelopmentItem: developmentItemScreen,
    DocumentDefinitions: documentDefinitionsScreen,
    DocumentDefinition: documentDefinitionScreen,
    EntityClassificationTypes: entityClassificationTypesScreen,
    EntityClassificationType: entityClassificationTypeScreen,
    ProcessDefinitionClassificationTypes: processClassificationTypesScreen,
    ProcessDefinitionClassificationType: processClassificationTypeScreen,
    BatchExecutions: batchInstancesScreen,
    BatchExecution: batchInstanceScreen,
    ProcessDefinitions: processDefinitionsScreen,
    ProcessDefinition: processDefinitionScreen,
    FxRates: fxRatesScreen,
    FxRate: fxRateScreen,
    GeneralSettings: parametersScreen,
    GenericDashboard: genericDashboardScreen,
    GenericScreen: genericScreenScreen,
    GlobalPortfolioCompliancePolicy: globalPortfolioComplianceScreen,
    GlobalPortfolioDealingPolicy: globalPortfolioDealingScreen,
    HistoricalValues: portfolioHistoricalValuesScreen,
    History: changesHistoryScreen,
    Indexes: indexesScreen,
    Index: indexScreen,
    InvestorClassificationTypes: investorClassificationTypesScreen,
    InvestorClassificationType: investorClassificationTypeScreen,
    RoleRelationshipClassificationTypes: roleRelationshipClassificationTypesScreen,
    RoleRelationshipClassificationType: roleRelationshipClassificationTypeScreen,
    NoteClassificationTypes: noteClassificationTypesScreen,
    NoteClassificationType: noteClassificationTypeScreen,
    DevelopmentItemClassificationTypes: developmentItemClassificationTypesScreen,
    DevelopmentItemClassificationType: developmentItemClassificationTypeScreen,
    DocumentClassificationTypes: documentClassificationTypesScreen,
    DocumentClassificationType: documentClassificationTypeScreen,
    Investors: investorRelationshipsScreen,
    Investor: investorRelationshipScreen,
    Loans: loansScreen,
    Loan: loanScreen,
    MacroMonitoringCalls: monitoringMacroCallsScreen,
    MacroMonitoringCall: monitoringMacroCallScreen,
    MacroScriptCategories: macroScriptCategoriesScreen,
    MacroScriptCategory: macroScriptCategoryScreen,
    Macros: macroScriptsScreen,
    Macro: macroScriptScreen,
    MovementClassificationTypes: movementClassificationTypesScreen,
    MovementClassificationType: movementClassificationTypeScreen,
    MyPortfolios: myPortfoliosScreen,
    MyPortfolio: myPortfolioScreen,
    MyShareClasses: myShareClassesScreen,
    MyShareClass: myShareClassScreen,
    MySicavs: mySicavsScreen,
    MySicav: mySicavScreen,
    Notes: notesScreen,
    Note: noteScreen,
    People: peopleScreen,
    Person: personScreen,
    PortfolioComplianceScreen: portfoliosComplianceScreen,
    PortfolioTransactions: portfolioTransactionsScreen,
    PortfolioTransaction: portfolioTransactionScreen,
    Positions: positionsScreen,
    RegisterTransactions: registerTransactionsScreen,
    RegisterTransaction: registerTransactionScreen,
    Reports: reportsScreen,
    Report: reportScreen,
    Securities: securitiesScreen,
    Security: securityScreen,
    SecurityClassificationTypes: securityClassificationTypesScreen,
    SecurityClassificationType: securityClassificationTypeScreen,
    ServiceProvidersCollaborators: roleRelationshipsScreen,
    ServiceProvidersCollaborator: roleRelationshipScreen,
    Sicavs: sicavsScreen,
    Sicav: sicavScreen,
    StatisticDefinitionSets: statisticDefinitionSetsScreen,
    StatisticDefinitionSet: statisticDefinitionSetScreen,
    StressTestingScreen: stressTestingScreen,
    SubFunds: subFundsScreen,
    SubFund: subFundScreen,
    ProcessExecutions: processExecutionsScreen,
    ProcessExecution: processExecutionScreen,
    ProcessExecutionRenew: processExecutionRenewScreen,
    ProcessExecutionNew: processExecutionNewScreen,
    TemplateCategories: reportTemplateCategoriesScreen,
    TemplateCategory: reportTemplateCategoryScreen,
    Templates: reportTemplatesScreen,
    Template: reportTemplateScreen,
    Tenancies: tenanciesScreen,
    Tenancy: tenancyScreen,
    ExternalTemplateEdition: externalTemplateScreen,
    Traces: tracesScreen,
    TradeDates: tradeDatesScreen,
    TradeDate: tradeDateScreen,
    TradesScreen: portfolioTradesScreen,
    ValueAtRisks: valueAtRiskScreen,
    //FIXME ValueAtRisk: valueAtRiskDetailScreen,
    TenantManagers: tenantManagersScreen,
    TenantsAdministration: tenantsAdministrationScreen,
    Documents: documentsScreen,
    Document: documentScreen,
    PortfolioTransactionClassificationTypes: portfolioTransactionClassificationTypesScreen,
    PortfolioTransactionClassificationType: portfolioTransactionClassificationTypeScreen,
    Invoice: invoiceScreen,
    Invoices: invoicesScreen,
}
