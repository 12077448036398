import { Formik, FormikHelpers, FormikProps } from "formik";
import { IInvoiceItemModel } from "proxy/apiProxy";
import DialogPanel from "tools/components/DialogPanel";
import InvoiceItemData from "./InvoiceItemData";

export interface IInvoiceItemDialogProps {
    invoiceItem?: IInvoiceItemModel;
    isOpened: boolean;
    onSubmit: (invoiceItem: IInvoiceItemModel) => void;
    onCancel: () => void;
}

export default function InvoiceItemDialog({
    invoiceItem,
    onSubmit,
    onCancel,
    isOpened
}: IInvoiceItemDialogProps) {
    const handleSubmit = (values: IInvoiceItemModel, { setSubmitting }: FormikHelpers<IInvoiceItemModel>) => {
        onSubmit(values);
        setSubmitting(false);
    };

    const title = !invoiceItem ? "Add Invoice Item" : "Edit Invoice Item";

    if (!invoiceItem) {
        invoiceItem = { id: 0 };
    }

    return <Formik onSubmit={handleSubmit} initialValues={invoiceItem} enableReinitialize={true} validateOnMount={true} validate={validate}>{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm }: FormikProps<IInvoiceItemModel>) {
        return <DialogPanel
            onBackClick={onCancel}
            title={title}
            isOpened={isOpened}
            actions={[{
                disabled: !isValid || !dirty,
                label: "Ok",
                onClick: submitForm
            }]}>
            <InvoiceItemData />
        </DialogPanel>;
    }
}

function validate(invoiceItem: IInvoiceItemModel) {
    const errors: {
        [p in keyof IInvoiceItemModel]?: string;
    } = {};

    if (invoiceItem.amount !== undefined && typeof invoiceItem.amount !== "number") {
        errors.amount = "Amount must be a number";
    }
    if (invoiceItem.quantity !== undefined && typeof invoiceItem.quantity !== "number") {
        errors.quantity = "Quantity must be a number";
    }
    if (invoiceItem.tax !== undefined && typeof invoiceItem.tax !== "number") {
        errors.tax = "Tax must be a number";
    }
    if (invoiceItem.unitPrice !== undefined && typeof invoiceItem.unitPrice !== "number") {
        errors.unitPrice = "Unit price must be a number";
    }
    if (invoiceItem.taxRate !== undefined && typeof invoiceItem.taxRate !== "number") {
        errors.taxRate = "Tax rate must be a number";
    }
    if (invoiceItem.unit !== undefined && typeof invoiceItem.unit !== "number") {
        errors.unit = "Unit must be a number";
    }

    return Object.values(errors).filter(i => !!i).length ? errors : undefined;
}
