import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { IFeatureModel, IInvoiceItemModel, IInvoiceModel } from "proxy/apiProxy";
import { FieldArray, FieldArrayRenderProps, Formik, FormikHelpers, FormikProps } from 'formik';
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { DocumentsGrid } from "features/Document/DocumentsScreen/DocumentsGrid";
import InvoiceData from "./InvoiceData";
import { InvoiceItemsGrid } from "./InvoiceItemsGrid";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import InvoiceItemDialog from "./InvoiceItemDialog";

export const invoiceScreen = {
    route: "/invoices/:id/:tab?" as const,
    component: Invoice,
    label: "Invoice",
    tabs: {
        detail: "details",
        items: "items",
        documents: "documents"
    }
}

function Invoice() {
    const {
        invoiceCurrent, invoiceLoading,
        invoiceSaving, dictionaries,
        documentsLoading, documents
    } = useReduxSelections("invoice");

    const {
        invoiceDelete,
        invoiceSave,
        invoiceLoad,
        invoiceDocumentsLoad,
        invoiceAddEntityInDictionary,
    } = useReduxActions("invoice");
    const { } = useReduxSelections("parameters");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof invoiceScreen>()
    const idNum = useNumber(id);

    useEffect(() => {
        if (isNumber(idNum)) invoiceLoad(idNum);
    }, [idNum, invoiceLoad]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "documents" && !documentsLoading && !documents)
            invoiceDocumentsLoad(idNum)
    }, [idNum, invoiceDocumentsLoad, documents, documentsLoading, tabValue]);

    const [openRelatedDialog, setOpenRelatedDialog] = React.useState(false);
    const handleOpenRelatedDialog = () => setOpenRelatedDialog(true);
    const handleCloseRelatedDialog = () => setOpenRelatedDialog(false);

    const [editingItem, setEditingItem] = React.useState<{ item: IInvoiceItemModel, existingIndex: number | undefined } | undefined>();

    const extraActionButtons =
        (tabValue === "items")
            ? [{
                onClick: handleOpenRelatedDialog,
                label: "Add related",
                icon: AddIcon,
            }]
            : [];

    if (!invoiceCurrent) {
        return <DetailPanelLoading hasSubTitle={false} />;
    }

    const handleDelete = () => {
        if (!!invoiceCurrent?.id) {
            invoiceDelete(invoiceCurrent.id);
        }
    }

    const title = !!invoiceCurrent?.id ? 'Invoice' : 'New invoice';
    const handleSubmit = (values: IInvoiceModel, { setSubmitting }: FormikHelpers<IInvoiceModel>) => {
        invoiceSave(values);
        setSubmitting(false);
    }

    return <Formik onSubmit={handleSubmit} initialValues={invoiceCurrent} enableReinitialize={true} validateOnMount={true} >
        {
            formikProps => <FieldArray name={oProps<IInvoiceModel>().path("items")}>
                {fieldArrayRenderProps => renderForm(formikProps, fieldArrayRenderProps)}
            </FieldArray>
        }
    </Formik>;

    function renderForm({ values: formValues, dirty, isValid, validateForm, resetForm, submitForm }: FormikProps<IInvoiceModel>, { push, remove, replace }: FieldArrayRenderProps) {
        const tabs: ISubMenuTabs<typeof invoiceScreen>[] = [
            {
                label: "Data",
                value: "detail"
            },
            {
                label: "Items",
                value: "items"
            }
        ];

        if (formValues.id) {
            tabs.push({
                label: "Documents",
                value: "documents"
            });
        }

        const handleEditItem = (itemId: number) => {
            const item = formValues.items[itemId];
            setEditingItem({ item, existingIndex: itemId });
            setOpenRelatedDialog(true);
        }

        const handleItemEdited = (editedItem: IInvoiceItemModel) => {
            if (typeof editingItem?.existingIndex !== "undefined")
                replace(editingItem.existingIndex, editedItem);
            else
                push(editedItem);
            setEditingItem(undefined);
            handleCloseRelatedDialog();
        }

        const handleCloseItemDialog = () => {
            setEditingItem(undefined);
            handleCloseRelatedDialog();
        }

        const handleDeleteItem = (index: number) => {
            remove(index);
            setTimeout(() => validateForm(), 100);
        }

        return <WriteAccess value={IFeatureModel.ManagedPortfolioWrite}>
            <InvoiceItemDialog
                invoiceItem={editingItem?.item}
                isOpened={openRelatedDialog}
                onCancel={handleCloseItemDialog}
                onSubmit={handleItemEdited} />
            <DetailPanel
                isQuerying={invoiceLoading || invoiceSaving}
                tabs={tabs}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!invoiceCurrent?.id}
                onDeleteClick={handleDelete}
                saveAllowed={IFeatureModel.ManagedPortfolioWrite}
                deleteAllowed={IFeatureModel.ManagedPortfolioWrite}
                badge={!invoiceCurrent?.id ? "new" : undefined}
                actions={extraActionButtons}
                tabValue={tabValue}>
                {(tabValue === "detail") &&
                    <InvoiceData
                        currentValues={formValues}
                        entities={dictionaries.entities}
                        invoiceAddEntityInDictionary={invoiceAddEntityInDictionary} />
                }
                {(tabValue === "items") &&
                    <InvoiceItemsGrid
                        items={formValues.items ?? []}
                        onRowClick={handleEditItem} 
                        onRowDelete={handleDeleteItem} />
                }
                {(tabValue === "documents") &&
                    <DocumentsGrid
                        documents={documents ?? []}
                        dictionaries={dictionaries} />
                }
            </DetailPanel>
        </WriteAccess>
    }
}
