import produce from "immer";
import {
    IInvoiceModel, IInvoiceSummaryModel, IGetInvoiceModel, IGetInvoicesModel,
    IDocumentSummaryModel, IGetDocumentsModel
} from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IGetEntitySummary } from "features/Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export interface IState {
    invoiceAllLoading: boolean;
    invoices: IInvoiceSummaryModel[];
    invoiceLoading: boolean;
    invoiceCurrent?: IInvoiceModel;
    invoiceSaving: boolean;
    invoiceDeleting: boolean;
    dictionaries: GetAllSummaries;

    documents?: IDocumentSummaryModel[];
    documentsLoading: boolean;
}
export const ActionFactories = produceActionFactories({
    invoiceLoad: (payload: number) => payload,
    invoiceLoaded: (payload: IGetInvoiceModel) => payload,
    invoiceSave: (payload: IInvoiceModel) => payload,
    invoiceSaved: (payload: IInvoiceModel) => payload,
    invoiceDelete: (payload: number) => payload,
    invoiceDeleted: (payload: number) => payload,
    invoiceLoadAll: () => undefined,
    invoiceLoadedAll: (payload: IGetInvoicesModel) => payload,
    invoiceAddEntityInDictionary: (payload: IGetEntitySummary) => payload,

    invoiceDocumentsLoad: (payload: number) => payload,
    invoiceDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export function reducer(
    state: IState = {
        invoiceLoading: false,
        invoiceAllLoading: false,
        invoiceSaving: false,
        invoiceDeleting: false,
        invoices: [],
        dictionaries: {
            entities: {},
            securities: {},
            portfolios: {},
            transactions: {},
            cashMovements: {},
            relationships: {},
        },
        documentsLoading: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "invoiceDocumentsLoad":
                draft.documentsLoading = true;
                break;
            case "invoiceDocumentsLoaded":
                draft.documentsLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                break;

            case "invoiceAddEntityInDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                break;

            case "invoiceLoadAll":
                draft.invoiceAllLoading = true;
                break;
            case "invoiceLoadedAll": {
                draft.invoiceAllLoading = false;
                draft.invoices = action.payload.invoices;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            }
            case "invoiceLoad":
                draft.invoiceLoading = true;
                draft.invoiceCurrent = undefined;
                draft.documents = undefined;
                break;
            case "invoiceLoaded": {
                draft.invoiceLoading = false;
                const { invoice, entities } = action.payload;
                draft.invoiceCurrent = invoice;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...entities };
                break;
            }
            case "invoiceSave":
                draft.invoiceSaving = true;
                break;
            case "invoiceSaved":
                draft.invoiceSaving = false;
                const saved = action.payload;
                if (draft.invoiceCurrent) {
                    draft.invoiceCurrent = saved;
                }
                const existing = draft.invoices.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.invoices.push(saved);
                }
                break;
            case "invoiceDelete":
                draft.invoiceDeleting = true;
                break;
            case "invoiceDeleted":
                draft.invoiceDeleting = false;
                const deletedId = action.payload;
                if (draft.invoiceCurrent?.id === deletedId) {
                    delete draft.invoiceCurrent;
                }
                const idx = draft.invoices.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.invoices.splice(idx, 1);
                }
                break;
        }
    });
}
