import * as React from "react";
import { IInvoiceItemModel } from "proxy/apiProxy";
import ExtendedGrid, { IColumnDefinition, IGridState } from "tools/components/ExtendedGrid";

interface IInvoiceItemsGridProps {
    items: IInvoiceItemModel[];
    onRowClick: (index: number) => void;
    onRowDelete: (index: number) => void;
}

export function InvoiceItemsGrid({ items, onRowClick, onRowDelete }: IInvoiceItemsGridProps) {

    const indexedItems = React.useMemo(() => items.map((i, idx) => ({ ...i, rowIndex: idx })), [items]);

    const columns: IColumnDefinition[] = [
        {
            name: "productCode",
            title: "Product Code",
            getCellValue: (row: IInvoiceItemModel) => row.productCode,
            filteringEnabled: true,
        },
        {
            name: "amount",
            title: "Amount",
            getCellValue: (row: IInvoiceItemModel) => row.amount,
            filteringEnabled: true,
        },
        {
            name: "description",
            title: "Description",
            getCellValue: (row: IInvoiceItemModel) => row.description,
            filteringEnabled: true,
        },
        {
            name: "quantity",
            title: "Quantity",
            getCellValue: (row: IInvoiceItemModel) => row.quantity,
            filteringEnabled: true,
        },
        {
            name: "tax",
            title: "Tax",
            getCellValue: (row: IInvoiceItemModel) => row.tax,
            filteringEnabled: true,
        },
        {
            name: "taxRate",
            title: "Tax Rate",
            getCellValue: (row: IInvoiceItemModel) => row.taxRate,
            filteringEnabled: true,
        },
        {
            name: "unitPrice",
            title: "Unit Price",
            getCellValue: (row: IInvoiceItemModel) => row.unitPrice,
            filteringEnabled: true,
        },
        {
            name: "unit",
            title: "Unit",
            getCellValue: (row: IInvoiceItemModel) => row.unit,
            filteringEnabled: true,
        },

    ];

    const state: IGridState = {
        "amount": { width: 220 },
        "description": { width: 240, hidden: true },
        "quantity": { width: 220 },
        "productCode": { width: 240 },
        "tax": { width: 220 },
        "taxRate": { width: 220 },
        "unitPrice": { width: 220 },
        "unit": { width: 220 },
    };

    const getRowKey = (item: IInvoiceItemModel & { rowIndex: number }) => {
        if (item.id !== 0) return item.id;
        return `new_${indexedItems.indexOf(item)}`;
    }

    const handleRowSelect = ({ rowIndex }: IInvoiceItemModel & { rowIndex: number }) => onRowClick(rowIndex);
    const handleRowDelete = ({ rowIndex }: IInvoiceItemModel & { rowIndex: number }) => onRowDelete(rowIndex);

    return <ExtendedGrid
        getRowId={getRowKey}
        onRowClick={handleRowSelect}
        onRowDelete={handleRowDelete}
        defaultExportFileName="InvoiceItems.xlsx"
        columns={columns}
        rows={indexedItems}
        initialState={state}
        userCanGroup={true}
        defaultColumnWidth={120} />;
}
