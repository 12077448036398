import * as appNew from "./App/epics";
import * as tenantManager from "./TenantManager/epics";
import * as tenantsAdministration from "./TenantsAdministration/epics";
import * as entity from './Entity/epics';
import * as security from './Security/epics';
import * as processDefinitionLookup from './ProcessDefinitionLookup/epics';
import * as managedPortfolio from './ManagedPortfolio/epics';
import * as subFund from './Subfund/epics';
import * as position from './Position/epics';
import * as portfolioHistoricalValue from './PortfolioHistoricalValue/epics';
import * as reference from './Reference/epics';
import * as securityClassificationType from './Classification/securityEpics';
import * as investorClassificationType from './Classification/investorEpics';
import * as roleRelationshipClassificationType from './Classification/roleRelationshipEpics';
import * as movementClassificationType from './Classification/movementEpics';
import * as entityClassificationType from './Classification/entityEpics';
import * as noteClassificationType from './Classification/noteEpics';
import * as processClassificationType from './Classification/processEpics';
import * as developmentItemClassificationType from './Classification/developmentItemEpics';
import * as documentClassificationType from './Classification/documentEpics';
import * as genericDashboard from './GenericDashboard/epics';
import * as portfolioTransactionClassificationType from './Classification/portfolioTransactionEpics';
import * as developmentItem from './DevelopmentItem/epics';
import * as reportTemplate from './ReportTemplate/epics';
import * as monitoringMacroCall from './MonitoringMacroCall/epics';
import * as dashboard from './Dashboard/epics';
import * as report from './Report/epics';
import * as versioning from './Versioning/epics';
import * as reportTemplateCategory from './ReportTemplateCategory/epics';
import * as macroScriptCategory from './MacroScriptCategory/epics';
import * as macroScript from './MacroScript/epics';
import * as cashMovement from './CashMovement/epics';
import * as accountBalance from './AccountBalance/epics';
import * as bankAccount from './BankAccount/epics';
import * as note from './Note/epics';
import * as portfolioTransaction from './PortfolioTransaction/epics';
import * as registerTransaction from './RegisterTransaction/epics';
import * as connector from './Connectors/epics';
import * as batch from './Batch/epics';
import * as stressTest from './StressTest/epics';
import * as batchInstance from './BatchInstance/epics';
import * as relationship from './Relationship/epics';
import * as valueAtRisk from './ValueAtRisk/epics';
import * as parameters from './Parameters/epics';
import * as globalPortfolioCompliance from './GlobalPortfolioCompliance/epics';
import * as globalPortfolioDealing from './GlobalPortfolioDealing/epics';
import * as tradeDate from './TradeDate/epics';
import * as portfoliosCompliance from './PortfoliosCompliance/epics';
import * as indexes from './Indexes/epics';
import * as fxRates from './FxRates/epics';
import * as portfolioTrades from './PortfolioTrades/epics';
import * as statisticDefinitionSet from './StatisticDefinitionSet/epics';
import * as processDefinition from './ProcessDefinition/epics';
import * as processExecution from './ProcessExecution/epics';
import * as managedShareClass from './ManagedShareClass/epics';
import * as managedSicav from './ManagedSicav/epics';
import * as sicav from './Sicav/epics';
import * as trace from './Traces/epics';
import * as externalTemplate from './ExternalTemplate/epics';
import * as customScreen from './CustomScreen/epic';
import * as genericScreen from './GenericScreen/epics';
import * as documentDefinition from './DocumentDefinition/epics';
import * as tenancy from './Tenancy/epics';
import * as loan from './Loan/epics';
import * as document from './Document/epics';
import * as invoice from './Invoice/epics';

const AllEpics = {
    genericScreen,
    processDefinitionLookup,
    developmentItem,
    tenantsAdministration,
    processExecution,
    tenantManager,
    appNew,
    loan,
    tenancy,
    documentDefinition,
    customScreen,
    externalTemplate,
    registerTransaction,
    sicav,
    trace,
    managedSicav,
    managedShareClass,
    subFund,
    processDefinition,
    statisticDefinitionSet,
    portfolioTrades,
    fxRates,
    indexes,
    portfoliosCompliance,
    portfolioTransactionClassificationType,
    tradeBook: tradeDate,
    globalPortfolioCompliance,
    globalPortfolioDealing,
    parameters,
    entity,
    security,
    managedPortfolio,
    position,
    portfolioHistoricalValue,
    reference,
    securityClassificationType,
    investorClassificationType,
    movementClassificationType,
    roleRelationshipClassificationType,
    entityClassificationType,
    noteClassificationType,
    processClassificationType,
    developmentItemClassificationType,
    documentClassificationType,
    reportTemplate,
    monitoringMacroCall,
    dashboard,
    report,
    versioning,
    reportTemplateCategory,
    macroScriptCategory,
    macroScript,
    cashMovement,
    accountBalance,
    bankAccount,
    note,
    portfolioTransaction,
    connector,
    batch,
    stressTest,
    batchInstance,
    relationship,
    valueAtRisk,
    document,
    genericDashboard,
    invoice,
}
const toArray = <T>(obj: any) => Object.keys(obj).map(i => obj[i] as T);

export default Object.keys(AllEpics).reduce((a, key) => {
    const elt = AllEpics[key as keyof (typeof AllEpics)];
    return [...a, ...toArray(elt)];
}, [] as any[]);
