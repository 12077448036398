import { IInvoiceItemModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import { Grid } from "@material-ui/core";
import FormTextField from "tools/fieldComponents/FormTextField";

export default function InvoiceItemData() {
    return <Grid container={true} spacing={4} style={{ padding: "10px" }}>
        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceItemModel>().path("productCode")} label="Product Code" />
        </Grid>
        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceItemModel>().path("description")} label="Description" />
        </Grid>

        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceItemModel>().path("amount")} label="Amount" isNumber={true} />
        </Grid>
        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceItemModel>().path("quantity")} label="Quantity" isNumber={true} />
        </Grid>

        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceItemModel>().path("tax")} label="Tax" isNumber={true} />
        </Grid>
        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceItemModel>().path("taxRate")} label="Tax Rate" isNumber={true} />
        </Grid>

        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceItemModel>().path("unitPrice")} label="Unit Price" isNumber={true} />
        </Grid>
        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceItemModel>().path("unit")} label="Unit" isNumber={true} />
        </Grid>
    </Grid>;
}
