import { Epic } from "redux-observable";
import { merge } from "rxjs";
import { filter, map, mergeMap, share } from "rxjs/operators";
import { IGetInvoiceModel, IInvoiceModel, invoicesApi } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import { mapToPayload } from "lib/rxJsUtility";

export const loadInvoices: Epic<IAnyAction>
    = (action$) => action$.pipe(
        mapToPayload("invoice", "invoiceLoadAll"),
        mergeMap(() => invoicesApi.getAllAsync({})),
        map(ActionFactories.invoice.invoiceLoadedAll));

export const loadInvoice: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("invoice", "invoiceLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(map(() => ActionFactories.parameters.parametersLoad())),
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(id => invoicesApi.getAsync({ id })),
                map(ActionFactories.invoice.invoiceLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(() => ({
                    invoice: {
                        id: 0,
                        customerId: null,
                        vendorId: null,
                        currencyId: null,
                        items: [],
                    } as unknown as IInvoiceModel,
                    entities: {},
                } as IGetInvoiceModel)),
                map(ActionFactories.invoice.invoiceLoaded)));
    };
export const saveInvoice: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("invoice", "invoiceSave"),
        mergeMap(model => invoicesApi.saveAsync({ model })),
        map(ActionFactories.invoice.invoiceSaved));

export const deleteInvoice: Epic<IAnyAction>
    = action$ => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("invoice", "invoiceDelete"),
            mergeMap(id => invoicesApi.deleteAsync({ id }).then(() => id)),
            map(ActionFactories.invoice.invoiceDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("invoice", "invoiceDocumentsLoad"),
        mergeMap(id => invoicesApi.getDocumentsAsync({ id })),
        map(ActionFactories.invoice.invoiceDocumentsLoaded));
