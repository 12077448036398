import { IInvoiceModel, EntitySummaryModel } from "proxy/apiProxy";
import { oProps } from "tools/lib/utility";
import { useReduxSelections } from "tools/lib/reduxStoreAccess";
import EntitySummary from "components/summaries/EntitySummary";
import EntitySelectField from "components/searchers/EntitySelectField";
import { Box, Grid } from "@material-ui/core";
import { IGetEntitySummary } from "features/Reference/slice";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import FormTextField from "tools/fieldComponents/FormTextField";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";

export interface IInvoiceDataProps {
    currentValues: IInvoiceModel;
    invoiceAddEntityInDictionary: (payload: IGetEntitySummary) => void;
    entities: Record<string | number, EntitySummaryModel>;
}

export default function InvoiceData({ invoiceAddEntityInDictionary, entities }: IInvoiceDataProps) {
    const { referenceCountries = {} } = useReduxSelections("reference");
    return <Grid container={true} spacing={4} style={{ padding: "10px" }}>
        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceModel>().path("number")} label="Number" />
        </Grid>
        <Grid item={true} md={6}>
            <CurrencySelectField name={oProps<IInvoiceModel>().path("currencyId")} label="Currency" />
        </Grid>

        <Grid item={true} md={6}>
            <Box display="flex" flexDirection="column">
                <EntitySelectField name={oProps<IInvoiceModel>().path("customerId")} label="Customer" onSelected={invoiceAddEntityInDictionary} required={true} />
                <EntitySummary name={oProps<IInvoiceModel>().path("customerId")} label="Customer" countries={referenceCountries} entities={entities} />
            </Box>
        </Grid>
        <Grid item={true} md={6}>
            <Box display="flex" flexDirection="column">
                <EntitySelectField name={oProps<IInvoiceModel>().path("vendorId")} label="Vendor" onSelected={invoiceAddEntityInDictionary} required={true} />
                <EntitySummary name={oProps<IInvoiceModel>().path("vendorId")} label="Vendor" countries={referenceCountries} entities={entities} />
            </Box>
        </Grid>

        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceModel>().path("totalTax")} label="Total Tax" />
        </Grid>
        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceModel>().path("taxRate")} label="Tax Rate" />
        </Grid>

        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceModel>().path("customerTaxNumber")} label="Customer Tax Number" />
        </Grid>
        <Grid item={true} md={6}>
            <FormTextField name={oProps<IInvoiceModel>().path("vendorTaxNumber")} label="Vendor Tax Number" />
        </Grid>

        <Grid item={true} md={6}>
            <FormDatePickerField name={oProps<IInvoiceModel>().path("invoiceDate")} label="Invoice Date" />
        </Grid>
        <Grid item={true} md={6}>
            <FormDatePickerField name={oProps<IInvoiceModel>().path("dueDate")} label="Due Date" />
        </Grid>

        <Grid item={true} md={6}>
            <FormDatePickerField name={oProps<IInvoiceModel>().path("serviceStartDate")} label="Service Start Date" />
        </Grid>
        <Grid item={true} md={6}>
            <FormDatePickerField name={oProps<IInvoiceModel>().path("serviceEndDate")} label="Service End Date" />
        </Grid>
    </Grid>;
}
